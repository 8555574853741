@font-face {
  src: url(./fonts/Montserrat-ExtraLight.ttf);
  font-family: Montserrat-ExtraLight;
}

@font-face {
  src: url(./fonts/Montserrat-Bold.ttf);
  font-family: Montserrat-Bold;
}

@font-face {
  src: url(./fonts/Montserrat-Regular.ttf);
  font-family: Montserrat-Regular;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.Contact_layout {
  width: 100vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Banner {
  display: flex;
  width: 100vw;
  font-family: 'Montserrat-Bold';
}

.Each {
  width: 45vw;
  min-width: 220px !important;
  font-family: 'Montserrat-Regular';
}

.Sep {
  margin: 5px;
}

.Ontop {
  position: absolute;
}

.Add {
  display: flex;
  justify-content: flex-end;
  margin-right: 3vmin;
  margin-top: calc(3.7vmin + 45px);
}

.Add_outer {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
}

.Image {
  width: calc(5vw + 90px);
  margin-top: 10px;
  margin-bottom: 10px;
}

.Regular {
  font-family: 'Montserrat-Regular';
}

@media only screen and (max-width: 500px) {
  .Each {
    width: 75vw;
    min-width: 220px !important;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 501px) {
  .Each {
    width: 60vw;
    min-width: 220px !important;
  }
}